<template>
  <b-field>
    <b-datepicker
      :value="value"
      @input="update"
    />
  </b-field>
</template>

<script>
import Mixin from '@/components/Form/Mixin'

export default {
  mixins: [Mixin]
}
</script>
